// extracted by mini-css-extract-plugin
export var hidden = "subscribeform-module--hidden--d7c5f";
export var subscribeButton = "subscribeform-module--subscribeButton--ba04a";
export var subscribeFail = "subscribeform-module--subscribeFail--4a08e";
export var subscribeForm = "subscribeform-module--subscribeForm--5d832";
export var subscribeInput = "subscribeform-module--subscribeInput--2929e";
export var subscribeInputFailBorder = "subscribeform-module--subscribeInputFailBorder--eb9db";
export var subscribeInputSuccessBorder = "subscribeform-module--subscribeInputSuccessBorder--5bba9";
export var subscribeLabel = "subscribeform-module--subscribeLabel--24ecb";
export var subscribeSuccess = "subscribeform-module--subscribeSuccess--744a4";
export var visible = "subscribeform-module--visible--98418";